
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'FormInput',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: String,
    placeholder: String,
    className: {
      type: String,
      default: ''
    },
    labelClassName: {
      default: '',
      type: String
    },
    validationMessage: {
      default: null,
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
    type: {
      default: 'text',
      type: String
    },
  },
  computed: {
    isRequired() {
      return typeof this.$attrs.required === 'string' || (typeof this.$attrs.required === 'boolean' && this.$attrs.required)
    },
    inputClassName() {
      return [
        this.className,
        'form-control',
        {
          'is-invalid': this.validationMessage !== null
        }
      ]
    },
    labelFullClassName() {
      return [
        this.labelClassName,
        'form-label',
        {
          'required': this.isRequired
        }
      ]
    }
  },
  emits: ['update:modelValue', 'input'],
});
