
import {defineComponent} from 'vue';
import Toast from "@/components/FlashMessages/Toast.vue";

export default defineComponent({
  name: 'FlashMessages',
  data: function () {
    return {
      messages: [],
    };
  },
  methods: {
    addFlash(type: string, text: string,) {
      this.messages.push({type, text});
    }
  },
  mounted() {
    this.emitter.on('add-flash', (message: Record<string, string>) => {
      const [type, text] = Object.entries(message)[0]
      this.addFlash(type, text);
    });
  },
  components: {
    Toast
  },
});
