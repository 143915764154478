import {createI18n, LocaleMessages, VueMessageType} from 'vue-i18n'

export const availableLocales = {'cs': 'Česky', 'en-GB': 'English', 'de': 'Deutsch'};

const datetimeFormats: Record<string, any> = {};
for (const locale of Object.keys(availableLocales)) {
    datetimeFormats[locale] = {
        date: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        date_time: {
            year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
        },
        weekday: {
            weekday: 'long',
        }
    };
}

export const datetimeFormatsUnicode: Record<string, any> = {
    cs: {
        date: 'dd. MM. yyyy',
        date_time: 'dd. MM. yyyy HH:mm',
        weekday: 'EEEE',
    },
    'en-GB': {
        date: 'dd/MM/yyyy',
        date_time: 'dd/MM/yyyy, hh:mm a',
        weekday: 'EEEE',
    },
    de: {
        date: 'dd. MM. yyyy',
        date_time: 'dd. MM. yyyy HH:mm',
        weekday: 'EEEE',
    }
};

export const datetimeInputFormatsUnicode: Record<string, any> = {
    cs: {
        date: 'd.M.yyyy',
        date_time: 'd.M.yyyy HH:mm',
        weekday: 'EEEE',
    },
    'en-GB': {
        date: 'd/M/yyyy',
        date_time: 'd/m/yyyy, hh:mm a',
        weekday: 'EEEE',
    },
    de: {
        date: 'd.M.yyyy',
        date_time: 'd.M.yyyy HH:mm',
        weekday: 'EEEE',
    }
};

function loadLocaleMessages(): LocaleMessages<VueMessageType> {
    const locales = require.context('./messages', true, /[A-Za-z0-9-_,\s]+\.json$/i)

    const messages: LocaleMessages<VueMessageType> = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })

    return messages
}

export default createI18n({
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE || 'cs',
    fallbackLocale: process.env.VUE_APP_I18N_LOCALE || 'cs',
    globalInjection: true,
    messages: loadLocaleMessages(),
    datetimeFormats,
})
