import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd7adac4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "toast-body d-flex align-items-center" }
const _hoisted_3 = {
  type: "button",
  class: "btn-link me-2 m-auto",
  "data-bs-dismiss": "toast",
  "aria-label": "Close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toast", _ctx.toastColor]),
    role: "alert",
    "aria-live": "assertive",
    "aria-atomic": "true",
    "data-bs-delay": "7000",
    ref: "toast"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InlineSvg, {
          src: require('@/assets/img/icons/' + _ctx.icons[_ctx.type] + '.svg'),
          height: "20",
          class: "me-3"
        }, null, 8, ["src"]),
        _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.text)), 1)
      ]),
      _createElementVNode("button", _hoisted_3, [
        _createVNode(_component_InlineSvg, {
          src: require('@/assets/img/icons/times.svg'),
          height: "25"
        }, null, 8, ["src"])
      ])
    ])
  ], 2))
}