import {App} from "vue";
import {getPath, redirectToRoute} from "@/utils/router";

export default {
    install: (app: App): void => {
        app.config.globalProperties.$getPath = (routeName: string) => {
            return getPath(routeName);
        }

        app.config.globalProperties.$redirectToRoute = (routeName: string) => {
            return redirectToRoute(routeName);
        }
    }
}