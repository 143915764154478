import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-487ca8ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "button__loader"
}
const _hoisted_3 = { class: "d-flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    class: _normalizeClass(_ctx.inputClassName),
    disabled: _ctx.disabled || _ctx.loading
  }, [
    (_ctx.showLoader)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_Loader, {
            "only-cart": "true",
            "loader-type": _ctx.loaderType
          }, null, 8, ["loader-type"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("span", _hoisted_3, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_InlineSvg, {
                key: 0,
                src: require('@/assets/img/icons/' + _ctx.icon + '.svg'),
                height: _ctx.height,
                class: _normalizeClass(_ctx.label ? 'me-2' : ''),
                fill: _ctx.fill
              }, null, 8, ["src", "height", "class", "fill"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.label) + " ", 1),
          (_ctx.iconAfter)
            ? (_openBlock(), _createBlock(_component_InlineSvg, {
                key: 1,
                src: require('../../assets/img/icons/' + _ctx.iconAfter + '.svg'),
                height: _ctx.height,
                class: "ms-2",
                fill: _ctx.fill
              }, null, 8, ["src", "height", "fill"]))
            : _createCommentVNode("", true)
        ])
      ], true)
    ])
  ], 10, _hoisted_1))
}