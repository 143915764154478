import Home from '../views/Home.vue'
import auth from "@/registration/router/middleware/auth";
import {setupRouter} from "@/utils/router";

export const appRoutes: Record<string, any> = {
    home: {
        component: Home,
        locales: {
            cs: {
                path: '/',
            },
            'en-GB': {
                path: '/en',
            },
            de: {
                path: '/de',
            }
        }
    },
    top100: {
        component: () => import('../views/Top100.vue'),
        locales: {
            cs: {
                path: '/top100',
            },
            'en-GB': {
                path: '/en/top100',
            },
            de: {
                path: '/de/top100',
            }
        }
    },
    login: {
        component: () => import('../views/Login.vue'),
        locales: {
            cs: {
                path: '/prihlaseni/:email',
            },
            'en-GB': {
                path: '/en/login/:email',
            },
            de: {
                path: '/de/anmeldung/:email',
            }
        }
    },
    register: {
        component: () => import('../views/Register.vue'),
        locales: {
            cs: {
                path: '/registrace/:email',
            },
            'en-GB': {
                path: '/en/registration/:email',
            },
            de: {
                path: '/de/registrierung/:email',
            }
        }
    },
    email_confirmation: {
        component: () => import('../views/EmailConfirmation.vue'),
        locales: {
            cs: {
                path: '/potvrzeni-emailu/:emailConfirmationToken',
            },
            'en-GB': {
                path: '/en/email-confirmation/:emailConfirmationToken',
            },
            de: {
                path: '/de/email-bestaetigung/:emailConfirmationToken',
            }
        }
    },
    profile: {
        component: () => import('../views/Profile.vue'),
        middleware: auth,
        locales: {
            cs: {
                path: '/profil',
            },
            'en-GB': {
                path: '/en/profile',
            },
            de: {
                path: '/de/profile',
            }
        }
    },
    edit_profile: {
        component: () => import('../views/EditProfile.vue'),
        middleware: auth,
        locales: {
            cs: {
                path: '/upravit-profil',
            },
            'en-GB': {
                path: '/en/edit-profile',
            },
            de: {
                path: '/de/profile-bearbeiten',
            }
        }
    },
    profile_saved: {
        component: () => import('../views/ProfileSaved.vue'),
        locales: {
            cs: {
                path: '/profil-ulozen',
            },
            'en-GB': {
                path: '/en/profile-saved',
            },
            de: {
                path: '/de/profile-gespeichert',
            }
        }
    },
    change_password: {
        component: () => import('../views/ChangePassword.vue'),
        middleware: auth,
        locales: {
            cs: {
                path: '/zmena-hesla',
            },
            'en-GB': {
                path: '/en/change-password',
            },
            de: {
                path: '/de/passwort-andern',
            }
        }
    },
    reset_password: {
        component: () => import('../views/ResetPassword.vue'),
        locales: {
            cs: {
                path: '/reset-hesla/:email',
            },
            'en-GB': {
                path: '/en/reset-password/:email',
            },
            de: {
                path: '/de/passwort-zurucksetzen/:email',
            }
        }
    },
    check_login_link: {
        component: () => import('../views/CheckLoginLink.vue'),
        locales: {
            cs: {
                path: '/jednorazove-prihlaseni/:params',
            },
            'en-GB': {
                path: '/en/onetime-login-link/:params',
            },
            de: {
                path: '/de/einmaliger-login-link/:params',
            }
        }
    },
    gdpr: {
        component: () => import('../views/GDPR.vue'),
        locales: {
            cs: {
                path: '/gdpr',
            },
            'en-GB': {
                path: '/en/gdpr',
            },
            de: {
                path: '/de/dsgvo',
            }
        }
    },
}

export default setupRouter(appRoutes, 'title.registration')
