
import {callAPI} from "@/utils/request";
import FormInput from "@/components/Form/FormInput.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import {useCookies} from "vue3-cookies";
import {logout} from "@/utils/security";
import {validateEmail} from "@/utils/validator";

export default {
  setup(): Record<string, any> {
    const {cookies} = useCookies();
    return {cookies};
  },
  beforeMount(): void {
    logout();
  },
  mounted(): void {
    this.$refs.emailUsername.$refs.input.focus();
  },
  data(): Record<string, any> {
    const predefinedEmailDomains = ['@seznam.cz', '@gmail.com', '@email.cz', '@centrum.cz', '@mail.ru', {'': this.$t('emailDomain.other')}];

    return {
      emailUsername: '',
      emailServer: '',
      emailDomain: predefinedEmailDomains[0],
      signInRegistrationButtonDisabled: true,
      signInRegistrationButtonLoading: false,
      predefinedEmailDomains,
    }
  },
  components: {
    FormSelect,
    FormButton,
    FormInput,
  },
  methods: {
    getEmailAddress(): string {
      let emailDomain = this.emailDomain ? this.emailDomain : ('@' + this.emailServer);
      return this.emailUsername + emailDomain;
    },
    submitEmail(): void {
      const email = this.getEmailAddress();
      if (!this.signInRegistrationButtonLoading) {
        this.signInRegistrationButtonLoading = true;
        callAPI('racer-exists', {email}, 'POST')
            .then((response: Record<string, any>) => {
              const locale = this.$i18n.locale;
              if (response.data.exists) {
                sessionStorage.setItem('login_type', 'login');
                this.$router.push({name: 'login_' + locale, params: {email}})
              } else {
                sessionStorage.setItem('login_type', 'registration');
                this.$router.push({name: 'register_' + locale, params: {email}})
              }
            })
            .finally(() => {
              this.signInRegistrationButtonLoading = false;
            });
      }
    },
    validateUsernameAndServer(): void {
      // Use very basic e-mail validation. No need for super clever solution. E-mail si later searched against real value in DB
      this.signInRegistrationButtonDisabled = !validateEmail(this.getEmailAddress());
    }
  },
};
