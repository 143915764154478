import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0520afc5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-container position-fixed top-0 start-50 translate-middle-x mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
      return (_openBlock(), _createBlock(_component_Toast, {
        key: message,
        type: message.type,
        text: message.text
      }, null, 8, ["type", "text"]))
    }), 128))
  ]))
}