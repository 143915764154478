
import {defineComponent} from 'vue';
import BsModal from 'bootstrap/js/src/modal.js'
import InlineSvg from "vue-inline-svg";

export default defineComponent({
  name: 'Modal',
  props: {
    id: {
      default: 'modal',
      type: String
    },
    title: String,
    modalClassName: {
      default: '',
      type: String
    },
    modalDialogClassName: {
      default: '',
      type: String
    },
  },
  mounted() {
    this.modal = new BsModal(this.$refs.modal)
    this.modal.show();

    this.$refs.modal.addEventListener('hidden.bs.modal', () => {
      this.$emit('modalHidden');
    });
  },
  beforeUnmount() {
    this.modal.hide();
  },
  data(): Record<string, any> {
    return {
      modal: null
    }
  },
  methods: {},
  components: {
    InlineSvg
  },
  emits: ['modalHidden']
});
