
import InlineSvg from 'vue-inline-svg';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Loader',
  props: {
    onlyCart: {
      default: false,
      type: Boolean
    },
    loaderType: {
      default: 'kart',
      type: String
    },
  },
  components: {
    InlineSvg,
  },
});
