import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export function isLogged(): boolean {
    return null !== cookies.get('jwtCookie')
}

export function login(token: string): void {
    cookies.set('jwtCookie', token, 60 * 15);
}

export function logout(): void {
    cookies.remove('jwtCookie');  // logout
}
