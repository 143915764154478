import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "id", "name", "placeholder", "aria-label", "value"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: _normalizeClass(_ctx.labelFullClassName)
        }, _toDisplayString(_ctx.label), 11, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("input", _mergeProps({
      class: _ctx.inputClassName,
      type: _ctx.type,
      id: _ctx.name,
      name: _ctx.name,
      placeholder: _ctx.placeholder ? _ctx.placeholder : _ctx.label,
      "aria-label": _ctx.label,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value), _ctx.$emit('input', $event.target.value)))
    }, _ctx.$attrs, { ref: "input" }), null, 16, _hoisted_2),
    (_ctx.validationMessage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "invalid-feedback",
          innerHTML: _ctx.validationMessage
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 64))
}