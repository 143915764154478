import mitt from 'mitt';
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export function accessObjectByDotNotation(object: Record<string, any>, property: string): any {
    return property.split('.').reduce((object, property) => object && object[property], object);
}

export const racerRequiredFields = ['firstName', 'lastName', 'birthDate', 'email', 'gender'];

export const emitter = mitt();

export function isKA() {
    return cookies.get('kartarena_device') !== null;
}
