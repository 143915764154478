
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'FormSelect',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    modelValue: String,
    className: {
      type: String,
      default: ''
    },
    labelClassName: {
      default: '',
      type: String
    },
    validationMessage: {
      default: null,
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
  },
  computed: {
    isRequired() {
      return typeof this.$attrs.required === 'string' || (typeof this.$attrs.required === 'boolean' && this.$attrs.required)
    },
    selectClassName() {
      return [
        this.className,
        'form-select',
        {
          'is-invalid': this.validationMessage !== null
        }
      ]
    },
    labelFullClassName() {
      return [
        this.labelClassName,
        'form-label',
        {
          'required': this.isRequired
        }
      ]
    }
  },
  methods: {
    optionValue(option: any) {
      return typeof option === 'object' ? Object.keys(option)[0] : option;
    },
    optionText(option: any) {
      return typeof option === 'object' ? option[this.optionValue(option)] : option;
    }
  },
  emits: ['update:modelValue', 'change'],
});
