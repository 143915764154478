import {isLogged} from "@/utils/security";
import {NavigationGuardNext} from "vue-router";
import i18n from "@/registration/i18n";

export default function auth(next: NavigationGuardNext): void {
    if (!isLogged()) {
        return next({
            name: 'home_' + i18n.global.locale.value
        })
    }

    return next()
}