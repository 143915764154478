import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "aria-label", "value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: _normalizeClass(_ctx.labelFullClassName)
        }, _toDisplayString(_ctx.label), 11, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("select", _mergeProps({
      class: _ctx.selectClassName,
      id: _ctx.name,
      name: _ctx.name,
      "aria-label": _ctx.label,
      value: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value), _ctx.$emit('change', $event.target.value)))
    }, _ctx.$attrs, { ref: "select" }), [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          value: _ctx.optionValue(option),
          key: option
        }, _toDisplayString(_ctx.optionText(option)), 9, _hoisted_3))
      }), 128))
    ], 16, _hoisted_2),
    (_ctx.validationMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.validationMessage), 1))
      : _createCommentVNode("", true)
  ], 64))
}