
import {defineComponent} from 'vue';
import Toast from 'bootstrap/js/src/toast.js'
import InlineSvg from "vue-inline-svg";

export default defineComponent({
  name: 'Toast',
  data() {
    return {
      icons: {'success': 'check', 'warning': 'triangle_exclamation', 'danger': 'times', 'info': 'square_info'}
    }
  },
  props: {
    text: {
      required: true,
      type: String
    },
    type: {
      default: 'success',
      type: String
    },
  },
  computed: {
    toastColor(): string {
      return 'bg-' + this.type + ' text-' + (this.type === 'warning' || this.type === 'info' ? 'black' : 'white');
    },
  },
  mounted() {
    new Toast(this.$refs.toast).show();
  },
  components: {
    InlineSvg
  },
});
