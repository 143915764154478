
import {defineComponent} from 'vue';
import Loader from "@/components/Loader.vue";
import InlineSvg from "vue-inline-svg";

export default defineComponent({
  name: 'FormButton',
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'submit'
    },
    disabled: {
      default: false,
      type: Boolean
    },
    icon: {
      type: String,
    },
    iconAfter: {
      type: String,
    },
    loading: {
      default: false,
      type: Boolean
    },
    className: {
      default: 'btn btn-primary w-100',
      type: String
    },
    additionalClassName: {
      default: '',
      type: String
    },
    fill: {
      default: 'white',
      type: String
    },
    height: {
      default: 20,
      type: Number
    },
    loaderType: {
      default: 'kart',
      type: String
    },
  },
  data(): Record<string, any> {
    return {
      showLoader: false,
      showLoaderTo: null
    }
  },
  computed: {
    inputClassName() {
      return [
        this.className,
        this.additionalClassName,
        {
          'loading': this.loading
        }
      ]
    }
  },
  watch: {
    'loading'(): void {
      if (!this.loading) {
        this.showLoader = false;
        clearTimeout(this.showLoaderTo);
      } else {
        this.showLoaderTo = setTimeout(() => {
          this.showLoader = true;
        }, 250)
      }
    }
  },
  components: {
    Loader,
    InlineSvg,
  },
});
