import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b7550ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-loader__container" }
const _hoisted_2 = {
  key: 0,
  class: "content-loader__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["content-loader", (!_ctx.onlyCart ? 'content-loader--with-text' : '') + (' content-loader--' + _ctx.loaderType)])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_InlineSvg, {
        src: require('@/assets/img/icons/' + _ctx.loaderType + '.svg'),
        height: "35",
        width: "50",
        fill: "white"
      }, null, 8, ["src"]),
      (!_ctx.onlyCart)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('loading')), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}