import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5440203a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-content bg-dark" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = {
  type: "button",
  class: "btn-link me-2 m-auto",
  "data-bs-dismiss": "modal",
  "aria-label": "Close"
}
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", _ctx.modalClassName]),
    tabindex: "-1",
    ref: "modal",
    id: _ctx.id
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog modal-dialog-centered modal-dialog-scrollable", _ctx.modalDialogClassName])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
            _createElementVNode("button", _hoisted_5, [
              _createVNode(_component_InlineSvg, {
                src: require('@/assets/img/icons/times.svg'),
                height: "25",
                fill: "#ffffff"
              }, null, 8, ["src"])
            ])
          ], true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ])
      ])
    ], 2)
  ], 10, _hoisted_1))
}