import {useCookies} from "vue3-cookies";
import {login} from "@/utils/security";
import i18n from "@/registration/i18n";
import {redirectToRoute} from "@/utils/router";
import {emitter} from "@/utils/app";

const {cookies} = useCookies();

class RequestException extends Error {
    data: any;
    response: any;

    constructor(message: string, data: any, response: any) {
        super(message);
        this.message = message;
        this.data = data;
        this.response = response;
        this.name = 'RequestException';
    }
}

let abortController: AbortController;

export function createAbortController(): void {
    if (typeof abortController !== 'undefined') {
        abortController.abort();
    }
    abortController = new AbortController();
}

export async function callAPI(
    endpoint: string,
    data: Record<string, unknown> = {},
    method = 'GET'
): Promise<Record<string, unknown>> {
    const headers: { [k: string]: string } = {'content-type': 'application/json', 'X-Frontend-Locale': i18n.global.locale.value};
    if (cookies.get('jwtCookie')) {
        headers['Authorization'] = 'Bearer ' + cookies.get('jwtCookie');
    }

    const fetchConf: { [k: string]: unknown } = {
        method: method,
        headers: new Headers(headers),
        credentials: 'include'
    };

    if (data && Object.keys(data).length !== 0) {
        fetchConf.method = 'POST';
        fetchConf.body = JSON.stringify(data);
    }

    fetchConf.signal = abortController.signal;

    let isError = false;
    let rawResponse: Response;

    let apiBasePath = 'checkout';
    if (process.env.VUE_APP_BASE_URL_REGISTRATION === window.location.origin) {
        apiBasePath = 'registration';
    }

    return await fetch(process.env.VUE_APP_BASE_URL_KARTCOMMANDER + '/api/' + apiBasePath + '/' + endpoint, fetchConf)
        .then((response) => {
            rawResponse = response;
            if (!response.ok) {
                isError = true;
            } else {
                const refreshToken = response.headers.get('x-refresh-token');
                if (refreshToken) {
                    login(refreshToken);
                }
            }

            return response;
        })
        .then((response) => response.json())
        .then((responseData) => {
            if (401 === rawResponse.status) {
                redirectToRoute('home');
                emitter.emit('add-flash', {'danger': i18n.global.t('userIsNotLogged')});
                throw i18n.global.t('userIsNotLogged')
            }

            if (isError) { // throw error with already processed (json) data
                if (rawResponse.status !== 422 && rawResponse.status !== 403 && rawResponse.status !== 404) {
                    emitter.emit('add-flash', {'danger': i18n.global.t('somethingWentWrong')});
                }
                throw new RequestException(`Failed with HTTP code ${rawResponse.status}:  ${rawResponse.statusText}`, responseData, rawResponse);
            }

            return responseData;
        });
}
